<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Niên vụ</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel with-scroll horizontal-tabs tabs-panel">


                    <div class="panel-body">

                        <div>
                            <div>
                                <ul class="nav nav-tabs">
                                    <li class="uib-tab nav-item"
                                        :class="{'active':!isShowFiles}">
                                        <a href="javascript:;" class="nav-link" @click="isShowFiles=false">Inventory Compare (So sánh niên vụ)</a>
                                    </li>
                                    <li class="uib-tab nav-item"
                                        :class="{'active':isShowFiles}"
                                        v-show="checkAcl('Upload')">
                                        <a href="javascript:;" class="nav-link" @click="isShowFiles=true">Files Uploaded</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div v-show="!isShowFiles">
                                        <div class="row mt-2">
                                            <label for="LastInventoryFileName" class="col-sm-2 control-label  mt-2">Old Inventory</label>
                                            <div class="col-sm-10">
                                                <div>
                                                    <button id="LastInventoryFileName" type="button" class="btn btn-default btn-xs dropdown-toggle"
                                                            data-bs-toggle="dropdown">
                                                        <div style="float:left;margin-bottom:0px;text-transform:none!important;">{{LastInventoryFileName}}</div>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li v-for="item in data" :key="item.Id" v-show="!item.IsDisable">
                                                            <a class="dropdown-item" href="javascript:void(0);" @click="LastInventoryFileId=item.Id,LastInventoryFileName=item.FileName">{{item.FileName}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <label for="NewInventoryFileName" class="col-sm-2 control-label  mt-2">New Inventory</label>
                                            <div class="col-sm-10">
                                                <div>
                                                    <button id="NewInventoryFileName" type="button" class="btn btn-default btn-xs dropdown-toggle"
                                                            data-bs-toggle="dropdown">
                                                        <div style="float:left;margin-bottom:0px;text-transform:none!important;">{{NewInventoryFileName}}</div>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li v-for="item in data" :key="item.Id" v-show="!item.IsDisable">
                                                            <a class="dropdown-item" href="javascript:void(0);" @click="NewInventoryFileId=item.Id,NewInventoryFileName=item.FileName">{{item.FileName}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <label class="checkbox-inline custom-checkbox nowrap">
                                                <input type="checkbox" @click="AlertFilter=!AlertFilter" :checked="AlertFilter?'true':null">
                                                <span>Alert only</span>
                                            </label>
                                        </div>

                                        <div class="mt-2">
                                            <button class="btn btn-primary" @click.prevent="ToExcel" v-show="NewInventoryFileId!=0&&LastInventoryFileId!=0">To Excel</button>
                                        </div>

                                        <table class="mt-3 table table-hover table-bordered" id="datatableNV" v-show="Object.keys(products).length>0">
                                            <thead>
                                                <tr>
                                                    <th>Code</th>
                                                    <th>Name</th>
                                                    <th>Niên Vụ</th>
                                                    <th>
                                                        <div style="width:80px">{{LastInventoryFileName}}</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:80px">{{NewInventoryFileName}}</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="item,key in products" :key="key" >
                                                    <tr :class="{'nvAlert':item.isAlert}" v-show="AlertFilter&&item.isAlert||!AlertFilter" >
                                                        <td :rowspan="getLength(item.NV)+1">
                                                            <a href="javascript:void(0);"
                                                               class="editable-click"
                                                               data-bs-toggle="tooltip"
                                                               data-bs-placement="top"
                                                               title="View file content"
                                                               @click="ViewHistory(key,item.Name)">
                                                                {{key}}
                                                            </a>
                                                        </td>
                                                        <td :rowspan="getLength(item.NV)+1">{{item.Name}}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr v-for="NV,nvkey in item.NV" :key="nvkey" v-show="AlertFilter&&item.isAlert||!AlertFilter">
                                                        <td>
                                                            <div v-show="!item.isEdit" @click="showNVDisable(item,key)">
                                                                <div :class="{'nvDisabled':NV['1'].IsDisabled}">
                                                                    Niên Vụ {{nvkey}}
                                                                </div>
                                                            </div>                                                         
                                                        </td>
                                                        <td>
                                                            <div :class="{'nvDisabled':NV['1'].IsDisabled}">
                                                                {{formatNumber(NV["1"].Remain)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div :class="{'nvDisabled':NV['1'].IsDisabled}">
                                                                {{formatNumber(NV["2"].Remain)}}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>


                                    </div>

                                    <div v-show="isShowFiles&&checkAcl('Upload')">
                                        <div style="margin:auto;text-align:center;font-size:18px" v-html="filetitle"></div>
                                        <div>
                                            <button v-show="ViewMode=='File'" type="button" class="btn btn-primary btn-with-icon" @click="ViewUpload"><i class="ion-android-upload"></i>Inventory Upload</button>

                                            <button type="button" class="btn btn-danger btn-with-icon" @click="ViewFile" v-show="ViewMode=='Product'"><i class="ion-android-cancel"></i>Back to Files List</button>

                                        </div>
                                        <div class="mt-3">
                                            <div v-show="ViewMode=='File'">
                                                <table class="table table-hover table-bordered" id="datatable">
                                                    <thead>
                                                        <tr>
                                                            <th id="columnName">Name</th>
                                                            <th>File Date</th>
                                                            <th>Created</th>
                                                            <th>Owner</th>
                                                            <th>Downloaded</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in data" :key="item.id">
                                                            <td>
                                                                <a href="javascript:void(0);"
                                                                   class="editable-click"
                                                                   data-bs-toggle="tooltip"
                                                                   data-bs-placement="top"
                                                                   title="View file content"
                                                                   @click="ViewContent(item.Id,item.FileName)">
                                                                    {{item.FileName}}
                                                                </a>
                                                            </td>
                                                            <td>{{formatDate(item.SelectedDate,true)}}</td>
                                                            <td>{{formatDate(item.UploadedDate)}}</td>
                                                            <td>{{item.CreatedBy}}</td>
                                                            <td>{{item.Downloaded}}</td>
                                                            <td>
                                                                <button type="button" class="btn btn-icon"
                                                                        @click="changeStatus(item.Id)"
                                                                        :class="{'btn-success':!item.IsDisable,'btn-danger':item.IsDisable}">
                                                                    <i :class="{'ion-android-checkmark-circle':!item.IsDisable,'ion-android-cancel':item.IsDisable}"></i>
                                                                </button>
                                                                <button type="button" class="btn btn-icon btn-primary"
                                                                        @click="downloadFile(item.Id)">
                                                                    <i class="ion-arrow-down-a"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-show="ViewMode=='Product'">
                                                <table class="table table-hover table-bordered" id="datatableFileProduct">
                                                    <thead>
                                                        <tr>
                                                            <th id="columnName">Code</th>
                                                            <th>Name</th>
                                                            <th>Remain</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in dataFileProducts" :key="item.id">
                                                            <td>{{item.Code}}</td>
                                                            <td>{{item.Name}}</td>
                                                            <td>{{formatNumber(item.Remain)}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <MDBModal v-model="showUpload" size="sm">
            <MDBModalHeader>
                <MDBModalTitle> Upload Niên Vụ </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                Date of Inventory (Ngày tồn kho của niên vụ):
                <Datepicker v-model="dateUpload" dark class="mb-3" autoApply>
                    <template #calendar-header="{ index, day }">
                        <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">
                            {{ day }}
                        </div>
                    </template>
                </Datepicker>
                <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-document"></i>Select a file</button>
                <div class="mt-3" id="uploadFileInfo">

                </div>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="UploadInventory"><i class="ion-android-upload"></i> Upload</button>
                <button type="button" class="btn btn-danger" @click="showUpload=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal v-model="isShowNVDisable" staticBackdrop>
            <MDBModalHeader>
                <MDBModalTitle>NienVu of <b>{{EditedNVDisable.Name}}</b></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow tag="form" class="g-3">
                    
                        <MDBCol md="12" v-for="NV,nvkey in EditedNVDisable.NV" :key="nvkey">
                            <input type="checkbox" :checked="NV['1'].IsDisabled?null:'checked'" :id="EditedNVDisable.Code+'NV_'+nvkey" /><label class="ms-3" :for="EditedNVDisable.Code+'NV_'+nvkey">Niên Vụ {{nvkey}}</label>
                        </MDBCol>
                    
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="saveNVDisabled(EditedNVDisable.Code)"><i class="ion-android-upload"></i> Save</button>
                <button type="button" class="btn btn-danger" @click="isShowNVDisable=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>


    </div>
</template>
<style>
    .nvAlert {
        background-color: #ff00005c !important;
    }
    .nvDisabled{
        color:#808080;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
    .table > thead > tr > th {
        white-space: break-spaces;
    }
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'

    import 'xlsx';
    //import 'xlsx-style-vue';
    import 'sheetjs-style';
    const XLSX = require('sheetjs-style');


    //========== Datetime picker
    //https://vue3datepicker.com/installation/#global
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'

    import {
        //MDBBtn,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,MDBRow,MDBCol
    } from 'mdb-vue-ui-kit';

    import c from '@/mylib/common'
    import { mapState,mapActions } from 'vuex'
    export default {
        name: `Nienvu`,
        data: function () {
            return {
                dateUpload:null,
                LayoutModel: {},
                LastInventoryFileName: '',
                LastInventoryFileId: 0,
                NewInventoryFileName: '',
                NewInventoryFileId: 0,
                data: [],
                AlertFilter: false,
                filetitle:'List of Niên Vụ Uploaded files',
                products: [],
                dataFileProducts:[],
                dataTable: null,
                dataTableProduct: null,
                datatableFileProduct:null,
                showUpload: false,
                isShowFiles: false,
                isShowNVDisable: false,
                EditedNVDisable: {
                    NV: {}},
                ViewMode:'File',
            }
        },
        
        components: {
            Datepicker,
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter, MDBRow, MDBCol
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAllFiles();
            },
            formatDate(dstr,notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatNumber(n) {                
                return c.formatNumber(n);
            },
            ViewHistory(code,name) {
                if (this.checkFullAcl('Product/ViewHistory'))
                    this.$store.dispatch("view/viewHistory", { code: code,name:name });
            },
            showNVDisable(item,key) {
                this.EditedNVDisable = item;
                this.EditedNVDisable["Code"] = key;
                this.isShowNVDisable = true;
            },
            getLength(obj) {
                if (Array.isArray(obj)) return obj.length;
                return Object.keys(obj).length;
            },
            async getCompare() {                
                this.loading(true);
                if (this.dataTableProduct != null) {
                    this.dataTableProduct.destroy();
                }
                const rs = await this.call({ Action: 'Nienvu/GetCompare', Object: { NewFileID: this.NewInventoryFileId, LastFileID: this.LastInventoryFileId } });
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    
                   
                } else {
                    this.products = {};
                    Object.keys(rs.Data["Prods"]).forEach(code => {
                        if (Object.keys(rs.Data["Prods"][code]).length < 2) return;
                        this.products[code] = {};
                        this.products[code]["Name"] = "";
                        this.products[code]["Disabled"] = "";
                        this.products[code]["NV"] = rs.Data["Prods"][code];
                        if (rs.Data["ProdsConfig"][code] != undefined) {
                            this.products[code]["Disabled"] = rs.Data["ProdsConfig"][code]["Disabled"];
                            //prods[code]["FileName"] = prodsConfig[code]["Disabled"];
                        }
                    });
                    //calulating
                    
                    Object.keys(this.products).forEach(code => {
                        this.calculateAlert(code);
                    });
                                      
                }
                this.loading(false);
            },
            calculateAlert(code) {
                const disabled = this.products[code]["Disabled"].split(",");
                const NV = this.products[code]["NV"];
                let isAlert = false;
                //check nv
                let nvKeys = Object.keys(NV);
                let totalOld = 0;
                //get total old nv
                let lastNVIndex = 0;
                let lastNVRemain = 0;
                for (let i = 0; i < nvKeys.length; i++) {
                    NV[nvKeys[i]]["1"].IsDisabled = false;
                    //add NV 2 if not exist
                    if (NV[nvKeys[i]]["2"] == undefined) NV[nvKeys[i]]["2"] = { Remain: 0 };
                    //skip calculate if disable
                    if (disabled.includes(nvKeys[i])) {
                        NV[nvKeys[i]]["1"].IsDisabled = true;
                        continue;
                    }
                    
                    totalOld += NV[nvKeys[i]]["2"].Remain;
                    lastNVIndex = i;
                    lastNVRemain = NV[nvKeys[i]]["2"].Remain;                    
                }
                //remove last one from totalold
                totalOld -= lastNVRemain;

                //get diff from new nv
                let nv1 = NV[nvKeys[lastNVIndex]]["1"];
                let nv2 = NV[nvKeys[lastNVIndex]]["2"];
                if (nv2 == undefined) {
                    nv2 = { "Remain": 0 }
                }
                let diff = Math.abs(nv1.Remain - nv2.Remain);
                //condition 1: if totalold>0 and diff>10%

                if (totalOld > 0 && diff * 100 / NV[nvKeys[lastNVIndex]]["1"].Remain > 10 )
                    isAlert = true;

                Object.keys(NV).forEach((item) => {
                    this.products[code]["Name"] = NV[item]["1"].Name;
                    this.products[code]["isEdit"] = false;
                });
                this.products[code]["isAlert"] = isAlert;
            },
            async saveNVDisabled(code) {
                if (!this.checkAcl('Upload')) return;
                this.loading(true);
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                //get data and calculate alert
                
                const NV = this.products[code]["NV"];
                
                //check nv
                let nvKeys = Object.keys(NV);
                
                let nvKeyDisabled = [];
                //get total old nv
                for (let i = 0; i < nvKeys.length; i++) {                    
                    if (!$('#'+code + "NV_" + nvKeys[i]).is(':checked'))
                        nvKeyDisabled.push(nvKeys[i]);
                }                
                //====

                const rs = await this.call({ Action: 'Nienvu/SaveNVDisabled', Object: { Code: code, NVkey: nvKeyDisabled.join(',') } });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });                    
                } else {
                    this.products[code].isEdit = false;
                    this.products[code]["Disabled"] = nvKeyDisabled.join(',');
                    this.calculateAlert(code);
                    this.showToast({
                        isShow: true, title: '', content: `Save NV success!`, type: 'success'
                    });
                    this.isShowNVDisable = false;
                    //recalculate alert

                }
            },
            async downloadFile(id) {
                this.loading(true);
                //get filename
                let fileName = '';
                let fileIndex = 0;
                for (let fileIndex = 0; fileIndex < this.data.length; fileIndex++) {
                    if (this.data[fileIndex].Id == id) {
                        fileName = this.data[fileIndex].FileName;
                        break;
                    }
                }
                if (fileName == '' || fileName == undefined) {
                    this.showToast({
                        isShow: true, title: '', content: `File not found!`, type: 'error'
                    });
                    return;
                }
                const rs = await this.call({ Action: 'Nienvu/DownloadFile', Object: { FileID: id, fileName: fileName } })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    c.SaveFile(rs.Data, "application/octet-stream", fileName);
                    this.data[fileIndex].Downloaded++;
                }
            },
            async getAllFiles() {
                
                this.loading(true);
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                const rs = await this.call({ Action: 'Nienvu/List' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });                    
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {                    
                    this.data = rs.Data;
                    setTimeout(this.doTable, 50);
                }
            },
            async changeStatus(id) {
                this.loading(true);
                const rs = await this.call({ Action: 'Nienvu/Toggle', Object: { FileID: id} })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    //if (this.dataTable != null) {
                    //    this.dataTable.destroy();
                    //}
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].Id == id) {
                            this.data[i].IsDisable = !this.data[i].IsDisable;
                            this.showToast({
                                isShow: true, title: '', content: `${this.data[i].FileName} is <span style="font-weight: bold;` + (this.data[i].IsDisable ? 'color: red;">Disabled' :'color: blue;">Enabled')+"</span>", type: 'success' });
                            break;
                        }
                    }
                    
                    //setTimeout(this.doTable, 200);
                }
            },
            doTable() {
                const _this = this;
                
                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                    "order": [[2, "desc"]]                    
                });
                this.loading(false);
            },
            doProductTable() {
                const _this = this;

                _this.dataTableProduct = $('#datatableProduct').DataTable({
                    responsive: true,
                    "order": [[2, "desc"]]
                });
                this.loading(false);
            },
            doTableProductFile() {
                const _this = this;

                _this.datatableFileProduct = $('#datatableFileProduct').DataTable({
                    responsive: true,
                    "order": [[2, "desc"]]
                });
                this.loading(false);
            },
            ViewUpload() {
                this.dateUpload = null;
                this.FileUpload = null;
                this.showUpload = true;
            },
            async ViewFile() {
                this.ViewMode = 'File';
                this.filetitle = 'List of Niên Vụ Uploaded files';
            },

            async ViewContent(fileId, filename) {
                if (!this.checkAcl('Upload')) return;                
                
                this.loading(true);
                if (this.dataTableDS != null) {
                    this.dataTableDS.destroy();
                }
                const rs = await this.call({
                    Action: 'Nienvu/ViewFile',
                    Object: { FileID: fileId }
                })

                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);

                } else {
                    this.dataFileProducts = rs.Data;
                    if (this.datatableFileProduct != null) this.datatableFileProduct.destroy();
                    this.ViewMode = 'Product';
                    this.filetitle = `<b>${filename}</b> (${Object.keys(rs.Data).length
                } items)`;
                    setTimeout(this.doTableProductFile, 200);
                }
            },
            SelectFileUpload() {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                el.accept = ".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
                //el.accept = "image/*";
                //el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)

                    // add first image, if available
                    if (el.files.length) {
                        
                        _this.FileUpload = el.files[0];
                        document.getElementById('uploadFileInfo').innerHTML = _this.FileUpload.name;
                    }
                });

                el.click();
            },
            async UploadInventory() {                
                if (this.dateUpload == null) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a date", type: 'error' });
                    return;
                }
                if (this.FileUpload == null) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a file to upload", type: 'error' });
                    return;
                }
                this.showUpload = false;
                this.loading(true);

                const rs = await this.call({ Action: 'Nienvu/Upload', Object: { SelectedDate: this.dateUpload }, Files: this.FileUpload })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    
                } else {
                    this.getAllFiles();
                }
            },
            ToExcel: function () {
                //https://stackoverflow.com/questions/67033105/add-dynamic-columns-with-xlsx-sheetjs
                //https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling

                //var workbook = XLSX.utils.table_to_book(document.getElementById("datatableNV"));
                // Process Data (add a new row)
                //var ws = workbook.Sheets["Sheet1"];
                var ws = XLSX.utils.table_to_sheet(document.getElementById("datatableNV"), {
                    display : true,
                });


                ws['!cols'] = [
                    { width: 12 },  // first column
                    { width: 36 },
                    { width: 12 },
                    { width: 40 },
                    { width: 40 },
                   
                ];



                let rowNum;
                let colNum;
                var range = XLSX.utils.decode_range(ws['!ref']);
                let isAlert = false;
                let code = '';
                for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                    let isDisable = false;
                    
                    for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
                        let Cell = ws[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ];
                        
                        //==== format cell ===
                        //if (rowNum == 0 && (colNum == 7
                        //    || colNum == (8 + this.GroupSaleSelectedIds.length))) {
                        //    Cell = {
                        //        s: {
                        //            border: {
                        //                right: {
                        //                    style: "thick",
                        //                    color: "000000"
                        //                }
                        //            }
                        //        },
                        //        t: 's',
                        //        v:''
                        //    };

                        //}

                        if (Cell == undefined) {
                            Cell = {
                                s: {
                                    border: {
                                        top: {
                                            style: "thin",
                                            color: "000000"
                                        },
                                        left: {
                                            style: "thin",
                                            color: "000000"
                                        },
                                        bottom: {
                                            style: "thin",
                                            color: "000000"
                                        },
                                        right: {
                                            style: "thin",
                                            color: "000000"
                                        },
                                    }
                                },
                                t: 's',
                                v:''
                            };
                        }
                        //check is alert
                        if (colNum == 0 && this.products[Cell.v] && Cell.v!="") {
                            code = Cell.v;
                            isAlert = this.products[code].isAlert;                            
                        }
                        //check is NV disabled
                        if (isNaN(Cell.v) && Cell.v.indexOf("Niên Vụ ") == 0 && this.products[code]) {
                            const nv = Cell.v.replace("Niên Vụ ", "");
                            
                            if (this.products[code]["NV"][nv] && this.products[code]["NV"][nv]["1"])
                                isDisable = this.products[code]["NV"][nv]["1"].IsDisabled;
                        }
                       
                        if (rowNum == 0) {
                            Cell.s = {
                                font: {
                                    name: 'Arial',
                                    sz: 10,
                                    bold: true,
                                    //color: { rgb: "FFAA00" }
                                },
                                alignment: {
                                    horizontal: 'center',
                                    vertical: 'center',
                                    wrapText: true
                                },
                                border: {
                                    top: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    right: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                }
                            }
                        }                        
                        else {
                            
                            //force text column if value is empty:
                            if (Cell.v == '' && Cell.t == 'z') {
                                Cell.t = 's';
                            }


                            //styling cell
                            Cell.s = {
                                font: {
                                    name: 'Arial',
                                    sz: 10,
                                    bold: false, 
                                    color: { rgb: isDisable ? 'BFBFBF':(isAlert ? 'FF0000' :'000000') }
                                },
                                numFmt: '#,##0',
                                alignment: {
                                    horizontal: 'center',
                                    wrapText: true
                                },
                                border: {
                                    top: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    right: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                }
                            }

                            //styling order cell, if <0 then red
                            //if ((colNum == (10 + this.GroupSaleSelectedIds.length) || colNum == (14 + this.GroupSaleSelectedIds.length)) && Cell.v < 0) {
                            //    Cell.s.font.color = { rgb: "FF0000" };
                            //}
                            //styling total colum
                            if (colNum == 0 && rowNum == range.e.r) {
                                Cell.s.alignment.horizontal = 'right';
                            }
                        }
                    }
                }


                ws['!rows'] = [
                    { 'hpt': 35 },// height for row 1
                ];


                //adding
                XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });




                ////delete row
                ////https://stackoverflow.com/questions/57649842/js-xlsx-how-to-delete-row-with-merged-cells
                //let worksheet = workbook.Sheets[workbook.SheetNames[0]]
                

                ////1. Take the range
                //var range = XLSX.utils.decode_range(worksheet['!ref']); //<-- start "select"
                //range.s.r = 0; // <-- start row
                //range.e.r = 12; // <-- end row
                //worksheet['!ref'] = XLSX.utils.encode_range(range);

                //// 2. Export to json   
                //let ws_json = XLSX.utils.sheet_to_json(worksheet)
                ////3. Create new workbook
                //var wb = XLSX.utils.book_new();
                //var ws = wb.Sheets[workbook.SheetNames[0]];
                ////4 Export json to sheet
                //let js = XLSX.utils.json_to_sheet(ws_json)
                ////5. Append sheet to workbook
                //XLSX.utils.book_append_sheet(wb, js, '');

                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, '');

                // Package and Release Data (`writeFile` tries to write and save an XLSB file)
                XLSX.writeFile(wb, "NienVu_Alert.xlsx");
            },
           
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {               
                if (newVal) {
                    this.start();
                }
            },
            isShowFiles(newVal) {
                if (newVal == true) {
                    if (this.checkAcl('Upload')) {
                        if (this.dataTable != null) {
                            this.dataTable.destroy();
                        }
                        setTimeout(this.doTable, 50);
                    }
                } 
            },
            LastInventoryFileId() {
                if (this.NewInventoryFileId > 0 && this.LastInventoryFileId > 0)
                    this.getCompare();
            },
            NewInventoryFileId() {
                if (this.NewInventoryFileId > 0 && this.LastInventoryFileId > 0)
                    this.getCompare();
            },

        }
    };
</script>
